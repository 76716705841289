@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Crete+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: #163879;
  letter-spacing: 2px;
}

.brand-name {
  font-family: "Lobster", cursive;
  font-size: 30px;
  letter-spacing: 2px;
}

/* .hero-section {
  z-index: 100;
} */

.hero-text {
  font-family: "Crete Round", serif;
}
.navbar {
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 100;
}

.navbar {
  font-family: "Roboto", sans-serif;
  font-weight: 540;
}
/* navbar menu toogle sm */
@media only screen and (max-width: 600px) {
  .show {
    background-color: rgb(172, 183, 187);
    color: #fff;
    padding: 20px;
  }
}
a:link {
  text-decoration: none;
}
/* 
.hero-section {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45)),
    url("pictures/cover.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  .hero-section {
    min-height: 70vh;
  }
}

@media only screen and (max-width: 600px) {
  .hero-section {
    min-height: 60vh;
  }
} */

.hero-video {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45));
  height: 70vh;
}
.fullscreen-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
  height: 80vh;
}

.hero-pic {
  margin-top: 20%;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
    text-align: center;
  }
}

.hero-section{
}

.featured-section {
  margin-top: 35vh;
}

.featured-images {
  min-height: 200px;
  max-height: 200px;
}

.section-header {
  margin: 10px 0 10px 0;
  padding: 0;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}
.host-btn {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.become-host {
  background-color: rgb(36, 31, 48);
}
.become-host-card-header {
  background-color: #ccc;
  font-size: large;
}
.done-img {
  max-width: 500px;
}
.h4 {
  color: #012970;
}
@media only screen and (max-width: 600px) {
  .done-img {
    max-width: 250px;
  }
  .successful-post-text {
    margin-top: 50px;
  }
}

.profile-thumb {
  min-height: 30vh;
  max-height: 30vh;
}

.card-header-create-profile {
  background-color: #ccc;
  min-height: 50px;
  padding: 10px;
}
.profile-image-div {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}
@media only screen and (min-width: 600px) {
  .dropdown-menu-bar {
    margin-left: 100px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
}

.my-listings-thumbnail {
  min-height: 250px;
  max-height: 250px;
}
.create-profile-button {
  margin-left: 50%;
}

/* spinner */
.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
/* end of spinner */
.caraousel-slider {
  padding-bottom: 10px;
  padding-top: 10px;
}

.d-block {
  max-height: 500px;
}
.card-booking-form-header {
  background-color: rgb(46, 44, 44);
  color: #fff;
  font-weight: 600;
}
.heading-p {
  color: #012970;
}
.category-cards {
  min-height: 370px;
}
.category-cards:hover {
  background-color: #242B2E;
}
.category-cards:hover .card-title{
  color: #fff;
}
.category-cards:hover p{
  color: #fff;
}
.category-img {
  height: 220px;
}
.find-roommates-content {
  background-color: #ccc;
  padding: 20px;
}
.find-roommates-content-cards {
  min-height: 450px;
}
.find-roommates-content-cards-pic {
  max-height: 250px;
}
.footer {
  background-color: #000;
  padding: 20px;
  color: #fff;
    bottom: 0;
    width: 100%;

    z-index: 1000; /* Optional: Ensure it stays above other elements */

}

.all-properties {
  margin-top: 100px;
}
.auth-btn {
  margin-top: 200px;
}
.mr-top {
  margin-top: 200px;
}
.mr-top-2 {
  margin-top: 150px;
}
.mr-top-slider {
  margin-top: 70px;
}
/* lg */
@media only screen and (min-width: 600px) {
  .main {
    margin-top: 70px;
  }
}
/* sm */
@media only screen and (max-width: 600px) {
  .main {
    margin-top: 50px;
  }
}

/*input file*/
.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 16px;
  width: 350px;
  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .button {
  display: inline-block;
  cursor: pointer;
  background: rgb(119, 0, 255);
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
}

.file-input:hover > .button {
  background: rgb(40, 136, 231);
  color: white;
}

.file-input > .label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen > .label {
  opacity: 1;
}
.fa-times-circle {
  color: rgb(228, 22, 22);
}
.fa-check-square {
  color: #15c56d;
}
/* Vertical center */
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

.top-div{
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.contact-container h2 {
  text-align: center;
}

.submit-button{
  display: inline-flex;
  justify-content: center;
  padding: 12px 24px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: normal;
  border-radius: 6px;
  color: white;
  background-color: #d97706;

  &:hover {
    background-color: #c26300;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(217, 119, 6, 0.2);
  }

}

/*End of vertical center */
