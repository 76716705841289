@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crete+Round&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Roboto", sans-serif;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  color: #163879;
  letter-spacing: 2px;
}

.brand-name {
  font-family: "Lobster", cursive;
  font-size: 30px;
  letter-spacing: 2px;
}

/* .hero-section {
  z-index: 100;
} */

.hero-text {
  font-family: "Crete Round", serif;
}
.navbar {
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 100;
}

.navbar {
  font-family: "Roboto", sans-serif;
  font-weight: 540;
}
/* navbar menu toogle sm */
@media only screen and (max-width: 600px) {
  .show {
    background-color: rgb(172, 183, 187);
    color: #fff;
    padding: 20px;
  }
}
a:link {
  text-decoration: none;
}
/* 
.hero-section {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45)),
    url("pictures/cover.jpg");

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  .hero-section {
    min-height: 70vh;
  }
}

@media only screen and (max-width: 600px) {
  .hero-section {
    min-height: 60vh;
  }
} */

.hero-video {
  background: linear-gradient(rgba(34, 30, 30, 0.45), rgba(71, 58, 58, 0.45));
  height: 70vh;
}
.fullscreen-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
  height: 80vh;
}

.hero-pic {
  margin-top: 20%;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
    text-align: center;
  }
}

.hero-section{
}

.featured-section {
  margin-top: 35vh;
}

.featured-images {
  min-height: 200px;
  max-height: 200px;
}

.section-header {
  margin: 10px 0 10px 0;
  padding: 0;
  font-size: 34px;
  line-height: 42px;
  font-weight: 700;
  color: #012970;
}
.host-btn {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.become-host {
  background-color: rgb(36, 31, 48);
}
.become-host-card-header {
  background-color: #ccc;
  font-size: large;
}
.done-img {
  max-width: 500px;
}
.h4 {
  color: #012970;
}
@media only screen and (max-width: 600px) {
  .done-img {
    max-width: 250px;
  }
  .successful-post-text {
    margin-top: 50px;
  }
}

.profile-thumb {
  min-height: 30vh;
  max-height: 30vh;
}

.card-header-create-profile {
  background-color: #ccc;
  min-height: 50px;
  padding: 10px;
}
.profile-image-div {
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
}
@media only screen and (min-width: 600px) {
  .dropdown-menu-bar {
    margin-left: 100px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  background-size: 100%;
}

.my-listings-thumbnail {
  min-height: 250px;
  max-height: 250px;
}
.create-profile-button {
  margin-left: 50%;
}

/* spinner */
.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}
/* end of spinner */
.caraousel-slider {
  padding-bottom: 10px;
  padding-top: 10px;
}

.d-block {
  max-height: 500px;
}
.card-booking-form-header {
  background-color: rgb(46, 44, 44);
  color: #fff;
  font-weight: 600;
}
.heading-p {
  color: #012970;
}
.category-cards {
  min-height: 370px;
}
.category-cards:hover {
  background-color: #242B2E;
}
.category-cards:hover .card-title{
  color: #fff;
}
.category-cards:hover p{
  color: #fff;
}
.category-img {
  height: 220px;
}
.find-roommates-content {
  background-color: #ccc;
  padding: 20px;
}
.find-roommates-content-cards {
  min-height: 450px;
}
.find-roommates-content-cards-pic {
  max-height: 250px;
}
.footer {
  background-color: #000;
  padding: 20px;
  color: #fff;
    bottom: 0;
    width: 100%;

    z-index: 1000; /* Optional: Ensure it stays above other elements */

}

.all-properties {
  margin-top: 100px;
}
.auth-btn {
  margin-top: 200px;
}
.mr-top {
  margin-top: 200px;
}
.mr-top-2 {
  margin-top: 150px;
}
.mr-top-slider {
  margin-top: 70px;
}
/* lg */
@media only screen and (min-width: 600px) {
  .main {
    margin-top: 70px;
  }
}
/* sm */
@media only screen and (max-width: 600px) {
  .main {
    margin-top: 50px;
  }
}

/*input file*/
.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 16px;
  width: 350px;
  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .button {
  display: inline-block;
  cursor: pointer;
  background: rgb(119, 0, 255);
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
  color: #fff;
}

.file-input:hover > .button {
  background: rgb(40, 136, 231);
  color: white;
}

.file-input > .label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen > .label {
  opacity: 1;
}
.fa-times-circle {
  color: rgb(228, 22, 22);
}
.fa-check-square {
  color: #15c56d;
}
/* Vertical center */
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

.top-div{
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.contact-container h2 {
  text-align: center;
}

.submit-button{
  display: inline-flex;
  justify-content: center;
  padding: 12px 24px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  font-weight: normal;
  border-radius: 6px;
  color: white;
  background-color: #d97706;

  &:hover {
    background-color: #c26300;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(217, 119, 6, 0.2);
  }

}

/*End of vertical center */

.property-button {
    height: 20vh;
    width: 190%;
    font-size: 2em;
    max-width: 190%;
}
.property-button1 {
    height: 20vh;
    width: 160.5%;
    font-size: 2em;
    max-width: 190%;
}

.cardy:hover {
background-color: white;
}

.mortgage-calculator-container {
    --White: hsl(0, 0%, 100%);
    --Slate-100: hsl(202, 86%, 94%);
    --Slate-300: hsl(203, 41%, 72%);
    --Slate-500: hsl(200, 26%, 54%);
    --Slate-700: hsl(200, 24%, 40%);
    --Slate-900: hsl(202, 55%, 16%);

    --Lime: hsl(61, 70%, 52%);
    --Red: hsl(4, 69%, 50%);

    font-family: "Plus Jakarta Sans", sans-serif;
    background-color: var(--Slate-100);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    padding: 0;
}

.mortgage-calculator-container .container {
    max-width: 1000px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 20px;
    border-radius: 30px;
    background-color: var(--White);
    display: flex;
    flex-direction: row;
    transition: all 0.3s ease;
}

.mortgage-calculator-container .left {
    background: white;
    padding: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    width: 50%;
}

.mortgage-calculator-container .right {
    background: var(--Slate-900);
    padding: 20px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 50%;
}

.mortgage-calculator-container .header {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mortgage-calculator-container .header h2 {
    color: var(--Slate-700);
}

.mortgage-calculator-container .btn-clear-all {
    border: none;
    background: none;
    font-size: 18px;
    color: var(--Slate-900);
    font-weight: 700;
    text-decoration: underline;
}

.mortgage-calculator-container .btn-clear-all:hover {
    cursor: pointer;
    text-transform: capitalize;
}

.mortgage-calculator-container .Form {
    margin-top: 20px;
}

.mortgage-calculator-container .Form label {
    display: block;
    margin-bottom: 5px;
}

.mortgage-calculator-container .split {
    display: flex;
    justify-content: space-between;
}

.mortgage-calculator-container .split .term,
.mortgage-calculator-container .split .rate {
    width: 48%;
}

.mortgage-calculator-container h3 {
    margin-top: 20px;
}

.mortgage-calculator-container .right h1 {
    color: #007bff;
    font-size: 2em;
}

.mortgage-calculator-container .right h4,
.mortgage-calculator-container .right h3,
.mortgage-calculator-container .right p {
    color: var(--White);
}

.mortgage-calculator-container .right .error-result {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 120px;
}

.mortgage-calculator-container .right .success-result {
    padding: 10px;
}

.mortgage-calculator-container .right .success-result h3 {
    margin-bottom: 20px;
}

.mortgage-calculator-container .right .success-result .result h1 {
    margin: 20px 0 20px;
}

.mortgage-calculator-container .right .success-result .result .b {
    margin: 20px 0 20px;
    border: 0.5px solid var(--Slate-100);
    outline: none;
}

.mortgage-calculator-container .right .success-result .result > div {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-bottom: 40px;
}

.mortgage-calculator-container .right .success-result .result {
    background-color: var(--Slate-900);
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(20, 20, 20, 0.6);
    border-top: 5px solid var(--Lime);
    margin-bottom: 20px;
    margin-top: 40px;
}

.mortgage-calculator-container .right .success-result .resultf {
    color: var(--Lime);
    font-size: 3rem;
}

.mortgage-calculator-container input {
    width: 100%;
    padding: 8px;
    border: 2px solid var(--Slate-100);
    border-radius: 4px;
    box-sizing: border-box;
    padding-left: 25px;
    height: 36px;
}

.mortgage-calculator-container .input-container {
    position: relative;
    margin-bottom: 10px;
}

.mortgage-calculator-container .input-container::before {
    content: attr(data-placeholder);
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 8px;
    background-color: var(--Slate-100);
    border-radius: 4px;
    pointer-events: none;
    white-space: nowrap;
    box-sizing: border-box;
}

.mortgage-calculator-container .start-placeholder::before {
    left: 0;
    border-radius: 4px 0 0 4px;
}

.mortgage-calculator-container .end-placeholder::before {
    right: 0;
    border-radius: 0 4px 4px 0;
}

.mortgage-calculator-container .radio input[type="radio"] {
    width: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    padding-left: 0;
    height: auto;
    margin-right: 10px;
}

.mortgage-calculator-container .radio label {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
}

.mortgage-calculator-container .radio-input {
    border: 2px solid var(--Slate-100);
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}

.mortgage-calculator-container .radio-input:hover {
    border: 1px solid var(--Lime);
}

.mortgage-calculator-container .radio {
    margin-bottom: 10px;
}

.mortgage-calculator-container input:focus {
    outline: none;
    border: 1px solid var(--Lime);
}

.mortgage-calculator-container .btn-calc {
    width: 100%;
    max-width: 250px;
    background-color: var(--Lime);
    padding: 10px;
    border: none;
    border-radius: 20px;
    color: var(--Slate-900);
    font-weight: 600;
    font-size: 18px;
    margin: 10px auto;
    text-align: center;
}

.mortgage-calculator-container .btn-calc:hover {
    cursor: pointer;
    background-color: rgb(205, 212, 107);
}

.mortgage-calculator-container .err {
    display: block;
    margin-top: -5px;
    color: var(--Red);
    font-size: 14px;
    font-weight: 500;
}

.mortgage-calculator-container .input.error {
    border: 2px solid var(--Red);
}

.mortgage-calculator-container .input-container.error::before {
    color: var(--White);
    content: attr(data-placeholder);
    background-color: var(--Red);
}

.mortgage-calculator-container .input.error::placeholder {
    color: var(--White);
}

.mortgage-calculator-container .error p {
    color: var(--White);
}

/* Responsive styles */
@media screen and (max-width: 800px) {
    .mortgage-calculator-container .container {
        flex-direction: column;
        background-color: var(--Slate-100);
        width: 100%;
        height: auto;
        margin: 100px auto;
        border-radius: 10px;
    }

    .mortgage-calculator-container .header {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
        margin-bottom: 20px;
        grid-gap: 20px;
        gap: 20px;
    }

    .mortgage-calculator-container .left,
    .mortgage-calculator-container .right {
        width: 100%;
    }

    .mortgage-calculator-container .right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: none;
    }

    .mortgage-calculator-container .split {
        flex-direction: column;
    }

    .mortgage-calculator-container .split .term,
    .mortgage-calculator-container .split .rate {
        width: 100%;
    }
}

