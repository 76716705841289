.property-button {
    height: 20vh;
    width: 190%;
    font-size: 2em;
    max-width: 190%;
}
.property-button1 {
    height: 20vh;
    width: 160.5%;
    font-size: 2em;
    max-width: 190%;
}

.cardy:hover {
background-color: white;
}
